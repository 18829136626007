import React, { useState } from 'react'
import SearchService from '../Shared/Services/Search'
import {
    ISearchResult,
    SearchResultItem,
} from '../Shared/Services/Search/interfaces'
import { StatusCode } from '../Shared/Services/Response.types'
import Search from './../SearchApp/Search'
import { SearchTheme } from '@apps/SearchApp/types'
import {CUSTOMER_STATE_CONTEXT} from "@frontendUtils/browser-storage";

function fetchSearchResults(
    query: string,
    features: string[],
    setResults: Function,
    customerType: string
) {
    let response: ISearchResult = {}

    SearchService.GetSearchResults(query, features, customerType)
        .then(result => {
            const status = result.status
            if (status === StatusCode.OK) {
                response = result
                setResults(response.searchHits)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

export const SearchModal = () => {
    const customerContext = JSON.parse(
        window.sessionStorage.getItem(CUSTOMER_STATE_CONTEXT) ||
        '{}'
    )

    const activeCustomer = customerContext && customerContext.ActiveCustomer
    const features = (activeCustomer && activeCustomer.features) || []
    const customerType = (activeCustomer && activeCustomer.customerType) || ''

    const [results, setResults] = useState<SearchResultItem[]>([])

    const recommendations = ['test', 'rekommendation'] //todo: get recommendations from content graph
    const searchPagePath = '/sok' //TODO: Get search page path from content graph?

    return (
        <div className={'search-modal hide'}>
            <div className={'search-modal__search-container'}>
                <Search
                    debounceT={300}
                    onAutoComplete={(value: string) => {
                        fetchSearchResults(
                            value,
                            features,
                            setResults,
                            customerType
                        )
                    }}
                    onClear={() => {
                        setResults([])
                    }}
                    recommendations={recommendations}
                    results={results}
                    searchPagePath={searchPagePath || ''}
                    theme={SearchTheme.Borderless}
                />
            </div>
        </div>
    )
}

export default SearchModal
