import {createRoot} from "react-dom/client";
import React from "react";
import SearchModal from "@apps/SearchModal/SearchModal";

const container = document.getElementById('js-search-modal');

if(container)
{
    const root = createRoot(container);

    root.render(<SearchModal />);
}